import { HotButtons, useAppDisplayModeContext } from '@archipro-design/aria';

import { useAppData } from '~/modules/root';
import { getHotButtons } from '~/modules/directory/util/get-hot-buttons';
import { visitBMEditor } from '~/modules/directory/util/tile-helpers';
import { useToggleHomePin } from '~/modules/home/hook/use-toggle-home-pin';
import { useState } from 'react';
import { canHomePin } from '~/modules/home/util/home-pin-helpers';
import type {
    Directory,
    DirectoryItemAdmin,
    EditableItem,
} from '~/modules/directory/type';
import { useItemAdminData } from '~/modules/directory/hook/use-item-admin-data';
import MessageModal from '~/modules/home/component/common/MessageModal';
import { getAdminCaption } from '~/modules/directory/util/get-admin-caption';
import AdminCaption from '~/modules/tile/component/admin-caption/AdminCaption';

export interface GetTileHotButtonsProps<T> {
    item: T extends EditableItem ? T : EditableItem & { ID: number };
    adminItem?: DirectoryItemAdmin;
    editOnSameTab?: boolean;
    onDeleteClick?: () => void;
}

interface UseTileHotButtonsProps {
    itemType: Directory;
    itemIDs?: number[];
}

// DOCUMENTATION: https://archipro.atlassian.net/wiki/spaces/AD/pages/1823801373/How+to+generate+topActions+hotButtons+for+a+tile

export const useTileHotButtons = ({
    itemType,
    itemIDs = [],
}: UseTileHotButtonsProps) => {
    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editProfile;
    const appDisplayMode = useAppDisplayModeContext();

    const adminTileMap = useItemAdminData(canEditAdmin, itemIDs);

    const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            setPinErrorMessage(error.Message);
        }
    });

    function getTileHotButtons<T>({
        item,
        adminItem,
        onDeleteClick,
        editOnSameTab = false,
    }: GetTileHotButtonsProps<T>) {
        const itemId = getItemId(item);
        const checkPinItem = adminItem || adminTileMap[itemId] || item;

        const hotButtons = getHotButtons({
            item: item,
            itemType: itemType,
            appDisplayMode,
            canEditAdmin,
            canEdit: Boolean(item.canEdit),
            onEditClick: visitBMEditor,
            onRepositionClick: (item) => {
                visitBMEditor(item, editOnSameTab, {
                    repositionThumb: true,
                });
            },
            repositionThumbLink: item.repositionThumbLink,
            pinned: !!checkPinItem.homePinned,
            onPinClick: canHomePin(rootGrants)
                ? () => {
                      toggleHomePin(itemId, !checkPinItem.homePinned);
                  }
                : undefined,
            editOnSameTab: editOnSameTab,
            onDeleteClick: onDeleteClick ? onDeleteClick : undefined,
        });

        return hotButtons;
    }

    function getTileTopActions<T>({
        item,
        adminItem,
        onDeleteClick,
        editOnSameTab = false,
    }: GetTileHotButtonsProps<T>) {
        const hotButtons = getTileHotButtons({
            item,
            adminItem,
            editOnSameTab,
            onDeleteClick,
        });

        return hotButtons ? <HotButtons items={hotButtons} /> : undefined;
    }

    const hotButtonModal = pinErrorMessage ? (
        <MessageModal
            title={'Warning'}
            content={pinErrorMessage}
            open={true}
            onConfirm={() => setPinErrorMessage(null)}
        />
    ) : null;

    const getTileAdminCaption = (adminItem: DirectoryItemAdmin | number) => {
        const defaultAdminItem =
            typeof adminItem === 'number' ? adminTileMap[adminItem] : adminItem;
        const adminCaption = getAdminCaption({
            appDisplayMode,
            item: defaultAdminItem,
            canEditAdmin,
        });
        return adminCaption ? (
            <AdminCaption adminCaption={adminCaption} />
        ) : undefined;
    };

    return {
        getTileHotButtons,
        getTileTopActions,
        getTileAdminCaption,
        pinErrorMessage,
        setPinErrorMessage,
        adminTileMap,
        hotButtonModal,
        appDisplayMode,
        canEditAdmin,
    };
};

const getItemId = (item: EditableItem): number => {
    return ('ID' in item ? item.ID : 'id' in item ? item.id : 0) as number;
};
